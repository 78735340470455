import { useState, useContext } from 'react';
import { Menu, MenuItem, Button, Box, Typography, Modal, Paper } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import useSipPhone from "./useSipPhone";
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';

const PREDICTIVE_ASTERISK_HOST = "eu1a.predictive.zorra.com";


const StatusDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState('Оффлайн'); // Начальный статус
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { credentials, setCredentials } = useContext(AuthContext);
  const navigate = useNavigate();

  const { unregister, connect, call, number } = useSipPhone();

  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const handleStatusChange = (newStatus) => {
  //   setStatus(newStatus === 'Уйти с линии' ? 'Оффлайн' : 'На линии');
  //   handleMenuClose();
  // };



  const handleStatusChange = (newStatus) => {
    if (newStatus === 'Уйти с линии') {
      setIsModalOpen(true); // Открыть модальное окно
    } else {
      setStatus('На линии');
      handleMenuClose();
    }
  };


  const online = async () => {
    const storedCredentials = localStorage.getItem("credentials");
    const storagedCredentials = storedCredentials && JSON.parse(storedCredentials)
    const activeCredentials = credentials || (storedCredentials && JSON.parse(storedCredentials));

    if (!activeCredentials || !activeCredentials.username || !activeCredentials.password) {
      console.warn("No credentials available, redirecting to /auth");
      return navigate("/auth");
    }

    let host = activeCredentials.host || storagedCredentials.host;

    // Настройка учетной записи SIP
    const sipAccount = {
      login: activeCredentials.username,
      password: activeCredentials.password,
      host: activeCredentials.host || storagedCredentials.host
    };

    // Сохранение данных и запуск подключения
    setCredentials(activeCredentials);
    handleStatusChange("Выйти на линию");

    connect(sipAccount);

    // Ждем события "ready" перед вызовом
    const waitForReady = new Promise((resolve) => {
      const interval = setInterval(() => {
        if (status === "ready") {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });

    try {
      await waitForReady;
      call(number);
    } catch (error) {
      console.error("Error during SIP connection or call:", error);
    }
  };



  const confirmGoOffline = () => {
    setStatus('Оффлайн');
    unregister();
    setIsModalOpen(false); // Закрыть модальное окно
    handleMenuClose();
  };

  const cancelGoOffline = () => {
    setIsModalOpen(false);
  };


  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {/* Кнопка открытия меню */}
        <Button
          color="white"
          variant="outlined"
          sx={{
            backgroundColor: status === 'На линии' ? 'green' : 'gray',
            color: 'white',
            marginRight: 2,
          }}
          endIcon={<ArrowDropDownIcon />}
          onClick={handleMenuOpen}
        >
          {status}
        </Button>
        {/* Выпадающее меню */}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'status-button',
          }}
        >
          <MenuItem onClick={online} disabled={status === 'На линии'}>
            Выйти на линию
          </MenuItem>
          <MenuItem onClick={() => handleStatusChange('Уйти с линии')} disabled={status === 'Оффлайн'}>
            Уйти с линии
          </MenuItem>

        </Menu>
      </Box>
      <Modal
        open={isModalOpen}
        onClose={cancelGoOffline}
        aria-labelledby="offline-modal-title"
        aria-describedby="offline-modal-description"
      >
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: 3,
            width: 300,
            textAlign: 'center',
          }}
        >
          <Typography id="offline-modal-title" variant="h6" gutterBottom>
            Вы уверены?
          </Typography>
          <Typography id="offline-modal-description" sx={{ mb: 2 }}>
            Пользователь уйдет в оффлайн. Вы уверены, что хотите уйти с линии?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" color="error" onClick={confirmGoOffline}>
              Уйти в оффлайн
            </Button>
            <Button variant="outlined" onClick={cancelGoOffline}>
              Отмена
            </Button>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default StatusDropdown;