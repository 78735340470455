import { useEffect, useContext, useState } from "react";
import useSipPhone from "./useSipPhone";
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from '@mui/material';


const PREDICTIVE_ASTERISK_HOST = "eu1a.predictive.zorra.com";

// const SIP_ACCOUNTS = {
//     peshkovs: {
//         login: "10979_7733044_aqua_5980",
//         password: "WZCM6j",
//         host: RU_ASTERISK_HOST,
//     },
//     amashkina: {
//         login: "11642_9487210_white_44303",
//         password: "fSGUaT",
//         host: RU_ASTERISK_HOST,
//     },
//     cupik393: {
//         login: "11650_9501722_navy_36879",
//         password: "ouAXrW",
//         host: RU_ASTERISK_HOST,
//     },
//     sashka: {
//         login: "aqua-quelea-55236",
//         password: "E677cd",
//         host: "157.230.97.172",
//     },
//     dashaFromLK: {
//         login: "lime-bison-13546",
//         password: "A1A95a",
//         host: "pbx-1.zorra.com",
//     },
//     dasha: {
//         login: "Pussy69",
//         password: "Cock33cm",
//         host: PREDICTIVE_ASTERISK_HOST,
//     },
//     client11811: {
//         login: "11811_10031926_maroon_38622",
//         password: "zoTR0E",
//         host: RU_ASTERISK_HOST,
//     },
//     visakok: {
//         login: "visakok412@eqvox.com",
//         password: "DXZvIIeX2lrd",
//         host: ENOT_ASTERISK_HOST,
//     },
// };



const Main = ({ children }) => {
    const { connect, call, hangup, answer, status, registered, remoteAudioRef, number, setNumber } = useSipPhone();
    // const [credentialsStorage, setCredentials] = useState({
    //     username: '',
    //     password: '',
    //     host: '',
    // });

    const { credentials, setCredentials } = useContext(AuthContext);
    const navigate = useNavigate();

    // Автоматически вызываем `call('100')`, когда статус становится "ready"
    // useEffect(() => {
    //     console.log('когда просто юзэффект')
    //     if (status === "ready") {
    //         console.log('когда позвонили ')
    //         call("100");
    //     }
    // }, [status, call]);


    useEffect(() => {
        console.log('remoteAudioRef', remoteAudioRef)
    }, [
        remoteAudioRef
    ])

    return (
        <div>
            <audio ref={remoteAudioRef} autoPlay controls />
            {children}
            <h1>SIP Phone</h1>
            <p>Status: {status}</p>
            <p>Registered: {registered ? "Yes" : "No"}</p>
            <h4>{registered && JSON.stringify(registered)}</h4>

            <TextField
                label="Номер (100)"
                variant="outlined"
                margin="normal"
                size="small"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                focused
                required
            />

            <div>
                <button onClick={() => call(number)}>Call</button>
                <button onClick={hangup}>Hangup</button>
                <button onClick={answer}>Answer</button>
            </div>


        </div>
    );
};

export default Main;

