import { useState, useRef } from "react";
import JsSIP from "jssip";

const useSipPhone = () => {
    const [status, setStatus] = useState("disconnected");
    const [registered, setRegistered] = useState(false);
    const [sipSession, setSipSession] = useState(null);
    const [number, setNumber] = useState('');
    const remoteAudioRef = useRef(null);
    const sipUaRef = useRef(null);

    const setStream = (session, outgoing = false) => {
        if (!session || !session.connection) return;

        if (outgoing) {
            console.log("Setting up outgoing stream...");
            // session.connection.ontrack = (e) => {
            //     console.log("Incoming track:", e);
            //     if (remoteAudioRef.current) {
            //         remoteAudioRef.current.srcObject = e.streams[0];
            //         remoteAudioRef.current.play();
            //     }
            // };


            session.connection.ontrack = (e) => {
                console.log("Incoming track:", e);
                if (remoteAudioRef.current) {
                    remoteAudioRef.current.srcObject = e.streams[0];
                    remoteAudioRef.current.play().catch((err) => {
                        console.error("Error playing audio:", err);
                    });
                }
            };


        } else {
            console.log("Setting up incoming stream...");
            const remoteStream = session.connection.getRemoteStreams()[0];
            if (remoteAudioRef.current && remoteStream) {
                remoteAudioRef.current.srcObject = remoteStream;
                remoteAudioRef.current.play();
            }
        }
    };

    const connect = (sipAccount) => {
        const login = `sip:${sipAccount.login}@${sipAccount.host}`;
        const password = sipAccount.password;
        const server = `wss://${sipAccount.host}:8089/ws`;

        const socket = new JsSIP.WebSocketInterface(server);
        const configuration = {
            sockets: [socket],
            uri: login,
            password,
            stun_servers: ["stun.l.google.com:19302"],
            trace_sip: true,

        };

        const sipUa = new JsSIP.UA(configuration);
        sipUa.start();
        sipUaRef.current = sipUa;


        navigator.mediaDevices.enumerateDevices().then((devices) => {
            devices.forEach((device) => {
                console.log(`${device.kind}: ${device.label}`);
            });
        });

        sipUa.on("newRTCSession", (data) => {
            const session = data.session;

            session.on("peerconnection", (pcEvent) => {
                const peerConnection = pcEvent.peerconnection;

                console.log("1111 PeerConnection created", peerConnection);

                peerConnection.ontrack = (e) => {
                    console.log("2222 Track event triggered", e);
                    if (remoteAudioRef.current) {
                        remoteAudioRef.current.srcObject = e.streams[0];
                        remoteAudioRef.current.play().catch((err) => {
                            console.error("3333 Error playing audio:", err);
                        });
                    }
                };
            });

            console.log("Session SDP (local):", session.connection.localDescription?.sdp);
            console.log("Session SDP (remote):", session.connection.remoteDescription?.sdp);
        });




        sipUa.once("connected", () => setStatus("connected"));
        sipUa.once("disconnected", () => {
            setStatus("disconnected");
            setRegistered(false);
        });
        sipUa.once("registered", () => {
            setRegistered(true);
            setStatus("ready");
        });
        sipUa.once("registrationFailed", () => setRegistered(false));

        sipUa.on("newRTCSession", (data) => {
            const session = data.session;
            setSipSession(session);

            if (session.direction === "incoming") {
                console.log("Incoming call...");
                setStream(session, false);
            }

            for (const event of ["progress", "confirmed", "ended", "failed"]) {
                session.on(event, (e) => {
                    console.log(`Session event: ${event}`, e);

                    if (event === "confirmed") setStream(session, true);
                    if (event === "ended" || event === "failed") setSipSession(null);
                });
            }
        });
    };

    const call = (number) => {
        if (status !== "ready") {
            console.error("Cannot place a call, not ready");
            return;
        }

        const options = {
            mediaConstraints: { audio: true, video: false },
        };

        const session = sipUaRef.current.call(number, options);
        setSipSession(session);
        setStatus("dialing");
    };

    const hangup = () => {
        if (sipSession) {
            sipSession.terminate();
            setSipSession(null);
            setStatus("ready");
        }
    };

    const answer = () => {
        if (sipSession && sipSession.direction === "incoming") {
            sipSession.answer({ mediaConstraints: { audio: true, video: false } });
            setStatus("conversation");
        }
    };

    const disconnect = async () => {
        if (!sipUaRef.current) {
            console.warn("SIP UA is not initialized");
            return;
        }

        try {
            // Завершить текущий SIP сеанс, если есть
            if (sipSession) {
                await sipSession.terminate();
                setSipSession(null);
            }

            // Остановить UserAgent
            await sipUaRef.current.stop();
            sipUaRef.current = null;

            // Обновить состояние
            setStatus("disconnected");
            setRegistered(false);

            console.log("SIP UserAgent disconnected successfully");
        } catch (error) {
            console.error("Error during SIP disconnection:", error);
        }
    };

    const unregister = () => {
        if (!sipUaRef.current) {
            console.warn("SIP UA is not initialized");
            return;
        }

        try {
            sipUaRef.current.unregister();
            setRegistered(false);
            console.log("SIP UserAgent unregistered successfully");
        } catch (error) {
            console.error("Error during SIP unregistration:", error);
        }
    };



    return {
        connect,
        disconnect,
        unregister,
        call,
        hangup,
        answer,
        status,
        registered,
        sipSession,
        remoteAudioRef, // Возвращаем ref для подключения в компоненте
        number,
        setNumber
    };
};

export default useSipPhone;