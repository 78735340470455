// AuthContext.js
import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
    });

    return (
        <AuthContext.Provider value={{ credentials, setCredentials }}>
            {children}
        </AuthContext.Provider>
    );
};