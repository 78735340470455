import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { TextField, Button, Box, Typography } from '@mui/material';

const Login = () => {
    const { setCredentials } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [host, setHost] = useState('');
    const navigate = useNavigate();

    const sipConnect = () => {
        // setIsAuthenticated(true);
        localStorage.setItem('credentials', JSON.stringify({ username, password, host }));
        navigate('/dashboard');
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!username || !password) {
            alert('Пожалуйста, заполните все поля');
        } else {
            setCredentials({ username, password }); // Сохранение в контекст
            sipConnect();
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 300,
                margin: 'auto',
                mt: 5,
                padding: 3,
                border: '1px solid #ccc',
                borderRadius: 2,
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Typography variant="h5" mb={3}>
                Вход
            </Typography>
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <TextField
                    label="Имя пользователя"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    focused
                    required
                />
                <TextField
                    label="Пароль"
                    variant="outlined"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    focused
                    required
                />
                <TextField
                    label="Ключ доступа"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={host}
                    onChange={(e) => setHost(e.target.value)}
                    focused
                    required
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    Войти
                </Button>
            </form>
        </Box>
    );
};

export default Login;