
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Auth';
import Main from './pages/Main';
import Dashboard from './pages/Dashboard';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const handleStatusChange = (newStatus) => {
    console.log('Статус активности изменен:', newStatus ? 'Активен' : 'Неактивен');
    // Можно сохранить новый статус на сервере
  };



  return (
    <Router>
      <Routes>
        {/* Маршрут для логина */}
        <Route
          path="/auth"
          element={<Login  />}
        />

        {/* Защищенный маршрут для дашборда */}
        <Route
          path="/dashboard"
          element={
            <Main>
            <Dashboard
              // onLogout={handleLogout}
              onStatusChange={handleStatusChange}
            />
          </Main>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
