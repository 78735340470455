import React, { useContext, useState } from 'react';

import { AppBar, Toolbar, Button, IconButton, Typography, Box, Avatar, Menu, MenuItem } from '@mui/material';
import { Logout } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom';

import StatusDropdown from './StatusDropdown';
import PersonImg from '../img/avatar.png';
import { AuthContext } from './AuthContext';



// const PersonImg = React.lazy(() => import('../img/avatar.jpeg'))


const DashboardHeader = ({ onLogout, onStatusChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState('active'); // Изначальный статус
  const { credentials } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    console.log('Пользователь вышел');
    localStorage.removeItem('credentials')
    navigate('/auth');
    // Добавьте логику выхода, например, очистку токенов
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#1976d2' }}>
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Дашборд
        </Typography>

        <StatusDropdown />
        
        <Box sx={{ marginLeft: 2, display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ mr: 2 }}>{credentials.username}</Typography>
          <Avatar
            sx={{ cursor: 'pointer' }}
            alt={credentials.username}
            src={PersonImg}
            onClick={handleMenuOpen}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleLogout}>
              <Logout sx={{ mr: 1 }} /> Выйти
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardHeader;